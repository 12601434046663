<template>
  <span class="move-school-level" v-if="hasPerm('families.change_child')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      :ok-disabled="formInvalid"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Changer"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-school"></i> Changer les élèves de niveau scolaire</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div>
        <b>Les enfants suivants vont changer de classe: </b>
      </div>
      <div style="padding: 5px; border: solid 1px #ccc; margin-bottom: 20px">
        <div v-for="elt in elements" :key="elt.child.id" class="student-badge">
          {{ elt.individual.fullName() }}
        </div>
      </div>
      <b-row>
        <b-col>
          <b-form-group label="Niveau actuel">
            <b-input type="text" disabled v-if="currentLevel" :value="currentLevel.fullName"></b-input>
            <b-input type="text" disabled v-else value="Niveau non défini"></b-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="school-levels-groups"
            label="Changer vers"
            label-for="school-levels"
          >
            <b-form-select
              v-model="selectedSchoolLevel"
              id="school-levels"
            >
            <b-form-select-option
              :value="elt"
              v-for="elt in schoolLevels"
              :key="elt.id"
            >
              {{ elt.fullName }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <a href @click.prevent="setByAge()" v-if="schoolYear">
            Définir à partir de l'age du 1er enfant sélectionné pour {{ schoolYear.name }}
          </a>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import { makeSchoolLevel } from '@/types/schools'
import { getSchoolLevelAge } from '@/utils/dates'
import { BackendApi } from '@/utils/http'

export default {
  name: 'MoveSchoolLevelModal',
  mixins: [BackendMixin],
  props: {
    elements: Array,
    allSchoolLevels: Array,
    currentLevel: Object,
    schoolYear: Object,
  },
  data() {
    return {
      errorText: '',
      selectedSchoolLevel: null,
      schoolLevels: [],
    }
  },
  watch: {
    currentLevel: function() {
      this.init()
      this.autoSelect()
    },
    elements: function() {
      this.autoSelect()
    },
    allSchoolLevels: function() {
      this.init()
    },
    schoolYear: function() {
      if (this.schoolYear) {
        this.init()
      }
    },
  },
  mounted() {
    this.init()
    this.autoSelect()
  },
  computed: {
    formInvalid() {
      return (this.selectedSchoolLevel === null)
    },
    modalId() {
      return 'bv-modal-move-school-level'
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    checkClassInProgress(classInList) {
      return classInList === this.schoolClass
    },
    init() {
      this.schoolLevels = [makeSchoolLevel({ full_name: 'A définir', })].concat(this.allSchoolLevels)
    },
    autoSelect() {
      if (this.currentLevel && this.currentLevel.id) {
        let index = this.allSchoolLevels.map(elt => elt.id).indexOf(this.currentLevel.id)
        if (index >= 0 && (index < this.allSchoolLevels.length - 2)) {
          // Par défaut niveau suivant
          index += 1
        }
        if (index >= 0 && (index < this.allSchoolLevels.length - 1)) {
          this.selectedSchoolLevel = this.allSchoolLevels[index]
        }
      } else {
        this.setByAge()
      }
    },
    setByAge() {
      const birthDates = this.elements.map(elt => elt.individual.birthDate).filter(birthDate => birthDate)
      if (birthDates.length) {
        const birthDate = birthDates[0]
        if (birthDate && this.schoolYear) {
          const firstAge = getSchoolLevelAge(birthDate, this.schoolYear)
          for (const schoolLevel of this.allSchoolLevels) {
            if (schoolLevel.age === firstAge) {
              this.selectedSchoolLevel = schoolLevel
              break
            }
          }
        }
      }
    },
    async onSave() {
      if (!this.formInvalid) {
        let url = '/api/change-school-level/'
        const backendApi = new BackendApi('post', url)
        try {
          const data = {
            'children': this.elements.map(elt => elt.child.id),
            'school_level': this.selectedSchoolLevel.id,
          }
          await backendApi.callApi(data)
          await this.addSuccess('Les enfants ont été changés de niveau')
          this.$bvModal.hide(this.modalId)
          this.selectedSchoolLevel = null
          this.$emit('done', this.selectedSchoolLevel)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<style scoped>
.student-badge{
  display: inline;
  border: 1px grey solid;
  border-radius: 5px;
  padding: 2px;
  margin: 2px;
  background-color: aliceblue;
}
.flex-wrap{
  display: flex;
  flex-wrap: wrap;
}
</style>
